/* eslint-disable no-undef */
import mixpanel from 'mixpanel-browser';

mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY, { debug: false });
const TRACKING_ACTIVE = true;

const actions = {
  identify: (id) => {
    if (TRACKING_ACTIVE) mixpanel.identify(id);
  },
  alias: (id) => {
    if (TRACKING_ACTIVE) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (TRACKING_ACTIVE) mixpanel.track(name, props);
  },
  register: (props) => {
    if (TRACKING_ACTIVE) mixpanel.register(props);
  },
  people: {
    set: (props) => {
      if (TRACKING_ACTIVE) mixpanel.people.set(props);
    },
    increment: (props) => {
      if (TRACKING_ACTIVE) mixpanel.people.increment(props);
    }
  },
  reset: () => {
    if (TRACKING_ACTIVE) mixpanel.reset();
  }
};

export let Mixpanel = actions;
