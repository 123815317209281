import MatPagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import React from 'react';

const Pagination = ({ count, onChange, page }) => {
  return (
    <Stack spacing={2}>
      <MatPagination
        onChange={onChange}
        page={page}
        count={count}
        variant="outlined"
        shape="rounded"
      />
    </Stack>
  );
};

export default Pagination;
