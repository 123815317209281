import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Box, FormHelperText, IconButton, InputBase, InputLabel } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import { getError } from 'constants/common';
import { number, specialCharacter, text } from 'constants/inputTypes';
import { Controller } from 'react-hook-form';
import { InputSkeletton } from '../../WebSkeleton';
import AdditionalInfo from '../AdditionalInfo';
import styles from './styles';

const Input = ({
  name,
  control,
  label,
  customStyles,
  placeholder,
  type = 'text',
  subType,
  fullWidth = true,
  rules,
  isSearch = false,
  onSearch,
  onClear,
  maxLength,
  variant = 'standard',
  className,
  disabled,
  additionalInfo,
  loading = false,
  isRequired,
  startIcon,
  onKeyDown,
  ...rest
}) => {
  const handleKeyDown = (e, value, fn) => {
    let invalidChars = [];

    if (type === 'number') {
      invalidChars = number;
    }

    if (subType === 'textOnly') {
      invalidChars = [...text, ...specialCharacter];
    }

    if (
      (invalidChars.length && invalidChars.includes(e.key)) ||
      (!value?.length && e.key === ' ')
    ) {
      e.preventDefault();
    }

    fn && fn(e);
  };

  if (loading) {
    return (
      <Box className={className} sx={customStyles}>
        <InputSkeletton label={label} />
      </Box>
    );
  }

  return (
    <Controller
      render={({ field, formState: { errors } }) => {
        const error = getError(name, errors);
        return (
          <FormControl
            sx={{ ...styles.wrapper, ...customStyles }}
            fullWidth={fullWidth}
            variant={variant}
            className={className}>
            {label && (
              <InputLabel className="clip" required={isRequired} shrink sx={styles.label}>
                {label}
              </InputLabel>
            )}
            <InputBase
              className={className}
              disabled={disabled}
              type={type}
              inputRef={field.ref}
              onBlur={field.onBlur}
              value={field.value}
              onKeyDown={(e) => {
                handleKeyDown(e, field.value, onKeyDown);
              }}
              error={!!error}
              sx={styles.input}
              onChange={field.onChange}
              placeholder={placeholder}
              startAdornment={startIcon}
              inputProps={{ maxLength: maxLength }}
              endAdornment={
                <>
                  {additionalInfo && <AdditionalInfo>{additionalInfo}</AdditionalInfo>}
                  {isSearch && (
                    <IconButton
                      disabled={disabled || loading}
                      sx={styles.searchIconWrapper}
                      onClick={onSearch}>
                      <SearchIcon disableTypography disablePointerEvents sx={styles.icon} />
                    </IconButton>
                  )}
                </>
              }
              {...rest}
            />
            <FormHelperText>{error?.message}</FormHelperText>
            {onClear && (
              <IconButton
                disabled={disabled || loading}
                sx={{ right: isSearch ? 45 : 0, ...styles.closeIconWrapper }}
                onClick={onClear}>
                {loading ? (
                  <CircularProgress
                    size={20}
                    sx={{ color: 'custom.gray', position: 'absolute', bottom: 10, right: 10 }}
                  />
                ) : (
                  <CloseIcon sx={styles.icon} />
                )}
              </IconButton>
            )}
          </FormControl>
        );
      }}
      name={name}
      control={control}
      rules={rules}
      {...rest}
    />
  );
};

export default Input;
