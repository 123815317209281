import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { Dropdown, Input, Radio } from '../../../common';
import { useQuery } from '@tanstack/react-query';
import {
  getCityList,
  getCountryList,
  getStateList
} from '../../../../redux/APICaller/userProfileAPIs';
import {
  cityListConverter,
  countryListConverter,
  stateListConverter
} from '../../../../constants/apiDataChanger';
import styles from '../styles';

const FacilityDetails = ({
  control,
  loading = false,
  watch,
  disabled,
  setValue,
  country,
  state,
  city
}) => {
  const { data: countryData = { data: [] } } = useQuery({
    queryKey: 'country',
    queryFn: () => {
      return getCountryList();
    },
    enabled: !disabled
  });

  const { data: stateData = { data: [] } } = useQuery({
    queryKey: ['state', watch('country')],
    queryFn: () => {
      return getStateList({ id: watch('country') });
    },
    enabled: !!watch('country') && !disabled
  });

  const { data: cityData = { data: [] } } = useQuery({
    queryKey: ['city', watch('state')],
    queryFn: () => {
      return getCityList({ id: watch('state') });
    },
    enabled: !!watch('state') && !disabled
  });

  useEffect(() => {
    if (watch('country') && !disabled) {
      setValue('state', '');
      setValue('city', '');
    }
  }, [watch('country'), disabled]);

  useEffect(() => {
    if (watch('state') && !disabled) {
      setValue('city', '');
    }
  }, [watch('state'), disabled]);

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' },
        alignItems: 'center',
        rowGap: '20px',
        columnGap: { xs: '2rem', md: '4rem' }
      }}>
      <Input
        disabled={disabled}
        name="facility_name"
        label="Facility Name"
        control={control}
        isRequired
        loading={loading}
        rules={{ required: 'This is a required field' }}
      />
      <Dropdown
        disabled={disabled}
        name="country"
        label="Country"
        options={countryListConverter(
          disabled ? [{ country_id: country, country_name: country }] : countryData?.data
        )}
        control={control}
        isRequired
        loading={loading}
        rules={{ required: 'This is a required field' }}
      />
      <Dropdown
        disabled={!watch('country') || disabled}
        name="state"
        label="State"
        control={control}
        isRequired
        loading={loading}
        rules={{ required: 'This is a required field' }}
        options={stateListConverter(
          disabled ? [{ city_id: state, city_name: state }] : stateData?.data
        )}
      />
      <Dropdown
        disabled={!watch('country') || !watch('state') || disabled}
        name="city"
        label="City"
        control={control}
        isRequired
        loading={loading}
        rules={{ required: 'This is a required field' }}
        options={cityListConverter(
          disabled ? [{ city_id: city, city_name: city }] : cityData?.data
        )}
      />
      <Input
        disabled={disabled}
        name="street"
        label="Street"
        control={control}
        isRequired
        loading={loading}
        rules={{ required: 'This is a required field' }}
      />
      <Input
        disabled={disabled}
        name="location_code"
        label="Facility/Location Code"
        control={control}
        isRequire
        isRequired
        loading={loading}
        rules={{ required: 'This is a required field' }}
      />
      <Input
        disabled={disabled}
        name="zip_code"
        label="Zip Code"
        control={control}
        isRequired
        loading={loading}
        onKeyPress={(event) => {
          if (!/[0-9+]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        rules={{
          validate: {
            minDigits: (value) =>
              (value?.length >= 5 && !!value) || 'There should be minimum 5 digits'
          }
        }}
      />
      <Input
        disabled={disabled}
        name="postal_address"
        label="Postal Address"
        control={control}
        isRequired
        loading={loading}
        rules={{ required: 'This is a required field' }}
      />
      <Input
        disabled={disabled}
        name="taluk"
        label="Taluk/Tehsil"
        control={control}
        isRequired
        loading={loading}
        rules={{ required: 'This is a required field' }}
      />
      <Radio
        disabled={disabled}
        name="status"
        label="Status"
        isRequired
        loading={loading}
        control={control}
        options={[
          {
            label: 'Enable',
            value: true
          }
          // {
          //   label: 'Disable',
          //   value: false
          // }
        ]}
      />
      <Input
        disabled={disabled}
        name="full_address"
        label="Full Address"
        control={control}
        isRequired
        loading={loading}
        rules={{ required: 'This is a required field' }}
      />
    </Box>
  );
};

export default FacilityDetails;
